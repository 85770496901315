<template>
    <div class="news-teams">
        <Anchor :id="'top'" />
        <div class="block mb-16">
            <div class="block-title">
                <div class="dot" />
                {{ $t('news.landing.title[2]') }}
            </div>
            <ListPagination
                :block-data="Teams"
                :label-open="true"
                :label="label"
                :router-name="'NewsTeamsItem'"
                :show-data="showData"
            />
        </div>
    </div>
</template>

<script>
import API from '@/api';
import { mapMutations } from 'vuex';
import ListPagination from '@/components/list/ListPagination.vue';

export default {
    components: {
        ListPagination,
    },
    data() {
        return {
            showData: false,
            label: [],
            Teams: {
                items: null,
            },
        };
    },
    watch: {
        $route() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        async init() {
            try {
                this.label = [];
                const res = await API.Article.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                console.log(res);
                this.Teams.items = res.data.raw.filter(
                    (value) => value.category === '團隊專欄',
                );
                const resTag = await API.ArticleTag.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                for (let i = 0; i < resTag.data.raw.length; i += 1) {
                    this.label.push(resTag.data.raw[i].value);
                }
                this.showData = true;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
        },
    },
};
</script>

<style lang="scss">
</style>
